.navbar{
  background: linear-gradient(to right, #000000, #272626);
  position: sticky;
  flex: auto;
  top: 0 ; /* Set the navbar to stick to the top of the screen */
  z-index: 1000;
}

.nav-link{
  margin: 10px; 
  color:aliceblue;
  text-align: center;
  
}

.nav-link:hover{
  color: #a5a2a2;
}

.active-nav-link {
  margin: 10px; 
  color: rgb(206, 0, 0) !important;
  text-align: center;
}

.nav-item{
  font-size: 30px;
}

.navbar-brand{
  padding-left: 15px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  position: absolute;
  right: 2%;
  bottom: 30%
}


.navbar-toggler {
  border: none;
  background: none;
  color: none;
  position: absolute;
  right: 2%;
  bottom: 30%;
  /* To change the size of the toggler */
  width: 32px; /* Your desired width */
  height: 50px; /* Your desired height */
}
