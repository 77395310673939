/* Style for the event card container */
.event-card {
    background: #ffffff; /* White background for the card */
    border-left: 5px solid red; /* Red line on the left as shown in the image */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Adding some shadow for depth */
    padding: 20px; /* Spacing inside the card */
    margin: 20px 0; /* Spacing between cards */
    cursor: pointer; /* Cursor change to indicate it's clickable */
}

.event-card h2 {
    color: #D32F2F; /* A red shade for the title */
    margin-bottom: 10px; /* Spacing after the title */
}

.event-card p {
    color: #333; /* Dark color for the text */
    margin-bottom: 10px; /* Spacing after the paragraph */
}

/* Style for the modal backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    z-index: 1000; /* Ensure it's above other content */
}

/* Style for the modal content box */
.modal-content {
    background: #ffffff; /* White background for the modal */
    padding: 20px; /* Spacing inside the modal */
    width: 90%; /* Modal width */
    max-width: 600px; /* Max width of the modal */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for the modal */
    position: relative; /* For absolute positioning of the close button */

    display: flex; /* Use flexbox to layout child elements */
    flex-direction: column; /* Stack child elements vertically */
    position: relative; /* Relative positioning for positioning of close button and others */
}

.modal-content button {
    border: none; /* Remove the border from buttons */
    background: none; /* Remove the background */
    cursor: pointer; /* Cursor change to indicate it's clickable */
    top: 10px; /* Position from the top */
    right: 10px; /* Position from the right */
}

.modal-content > button {
    align-self: flex-end; /* Align the close button to the right */
}

.modal-content h2 {
    color: #D32F2F; /* A red shade for the title */
    margin-bottom: 20px; /* Spacing after the title */
}

.modal-content p {
    color: #333; /* Dark color for the text */
    margin-bottom: 15px; /* Spacing after the paragraph */
}

/* Updated styles for the buttons container */
.ticket-buttons-container {
    display: flex; /* Use flexbox to layout buttons */
    flex-direction: column; /* Stack buttons and messages vertically */
    align-items: center; /* Center buttons and messages horizontally */
}

.reminder-buttons-container {
    display: flex;
    justify-content: space-between; /* Space out buttons */
    margin-top: 20px; /* Space above the buttons */
}

.modal-content .ticket-buttons-container > button:hover {
    background-color: #D32F2F; /* Red background on hover */
    color: #fff; /* White text on hover */
}

/* Add a container for the action buttons if it doesn't exist */
.modal-content .actions {
    margin-top: auto; /* Push the actions to the bottom */
    padding: 20px 0; /* Add some padding at the top and bottom */
}

/* Style adjustments for action buttons */
.modal-content .actions button {
    margin: 10px; /* Space around buttons */
}

/* Add a class for ticket information */
.ticket-info {
    text-align: center; /* Center the text */
    color: rgb(216, 6, 6); /* Red color for the text */
    margin-top: 20px; /* Space above the message */
}

.ticket-info > button {
    margin: 5px;
    border: 2px solid rgb(216, 6, 6); /* Red border for the button */
    border-radius: 6px;
}

.ticket-info > button:hover {
    color: white; /* White text on hover */
    background-color: rgb(216, 6, 6); /* Red background on hover */
}
