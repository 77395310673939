.search{
    background: linear-gradient(to right, #343333, #000000);
    position: sticky;
    top: 10%;
}

/* Apply this class to the container of your search bar */
.search-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    border-radius: 5px;
    margin: auto;
    width: 80%; /* Adjust this value based on your design needs */
    padding: 10px;
}

.search-input {
    padding: 10px;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    color: white;
    background: #555;
    flex-grow: 1; /* Make input grow to fill available space */
}

.search-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    background-image: linear-gradient(to right, #8B4513, #D2691E);
    cursor: pointer;
    white-space: nowrap; /* Ensure the button text doesn't wrap */
    width: 20%;
}

/* Removes the input field's outline when focused */
.search-input:focus {
    outline: none;
}


/* Removes the button's outline when focused */
.search-button:focus {
    outline: none;
}

/* Optional: Add some hover effects for the button */
.search-button:hover {
    background-image: linear-gradient(to right, #D2691E, #8B4513);
}
