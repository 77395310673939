#landing-main-container {
    background-color: #0f0f0f;
    color: white;
    padding-top: 15px;
    padding-bottom: 20px;
    min-height: 100vh;
}

.landing-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.landing-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: rgba(255, 0, 0, 0.555);
    color: white; 
    font-weight: bold;
    text-decoration: none; 
    border-radius: 5px; 
    transition: background-color 0.3s ease; 
}

.landing-link:hover {
    background-color: red; 
}


#welcome-container {
    background-color: #0f0f0f;
    color: white;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    display: grid;
    grid-template-columns: 1fr;
}

#welcome-background {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    grid-template-columns: 3;
    grid-template-rows: 2;
    z-index: 1;
    grid-row: 1;
    grid-column: 1;
}

.welcome-img {
    width: 32%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    align-items: center;
    margin: 0 auto;
    display: flex;
    opacity: 0.5;
}

#welcome-text-container {
    display: inline-block;
    z-index: 2;
    background: #3a3a3a;
    border-style: solid;
    border-radius: 10px;
    padding: 5px;
    width: 90%;
    height: min-content;
    /* min-height: 60%; */
    text-align: center;
    margin: 0 auto;
    grid-row: 1;
    grid-column: 1;
    margin-top: 10%;
    opacity: 0.95;
}

.landing-header {
    color: red;
}

#about-img {
    /* min-width: 30%; */
    max-width: 40%;
    object-fit: contain;
    border-style: solid;
    border-color: #0f0f0f;
    border-radius: 20px;
    margin: 10px;
}

#about-padding-img {
    align-self: center;
}

#instruments-container {
    display: flex;
    flex-direction: column;
}

#instruments-img {
    min-width: 160px;
    max-width: 30%;
    object-fit: contain;
    border-style: solid;
    border-color: #0f0f0f;
    border-radius: 20px;
    margin: 10px;
}
 
.instrument-type-img {
    max-width: 40%;
    object-fit: contain;
} 

.instrument-type-header {
    color: red;
    width: 100%;
}

#types-header {
    color: red;
    margin-top: 10px;
    grid-row: 1;
    grid-column: 1;
    align-self: flex-start;
}

.instrument-type-card {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 49%;
    gap: 10px;
    background-color: #0f0f0f;
    border-radius: 20px;
    padding: 10px;
}

#instrument-type-cards-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-columns: 2;
    gap: 10px;
    background-color: #3a3a3a;
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
}

.instrument-type-content {
    display: flex;
    flex-direction: column;
}

#landing-instrument-types-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    color: white;
    width: 100%;
}

p {
    font-size: 19pt;
}

@media (min-width: 717px){
    .format-image {
        display: none;
    }
}



