@charset "UTF-8";
/*!
 * Bootswatch v5.3.2 (https://bootswatch.com)
 * Theme: lux
 * Copyright 2012-2023 Thomas Park
 * Licensed under MIT
 * Based on Bootstrap
*/
/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 @font-face {
    font-family: 'TheFuture';
    src: url('../fonts/Josefin_Sans/JosefinSans-VariableFont_wght.ttf') format('opentype');
  }  

  .homeBackground{
    background-color: #000000;
    padding-bottom: 100%;
  }
