@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/Josefin_Sans/JosefinSans-VariableFont_wght.ttf') format('truetype');
  }
  
  .instrument-card {
    background: linear-gradient(to right, #181818, #3a3838);
    /* or for a background image: */
    /* background-image: url('path-to-your-image.jpg'); */
    background-size: cover;
}

.details-card {
  background: linear-gradient(to right, #181818, #3a3838);
    /* or for a background image: */
    /* background-image: url('path-to-your-image.jpg'); */
    background-size: cover;
}

.card{
  background: linear-gradient(to right, #000000, #000000);
    font-family: 'Josefin Sans', sans-serif;
}
