.top-Background {
  background-color: #0f0f0f;
  background-image: url('../../images/darkbackground.jpg');
  color: #ffffff;
}

.instrument-Background {
  background-color: #0f0f0f;
  padding-bottom: 30%;
  color: #ffffff;
}

.instrument-photo {
  width: 25%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.instrument-name {
  font-size: 28px;
  font-weight: bold;
  color: white;
  background-color: #A13D2D;
  padding: 5px 10px;
  border-radius: 10px;
  width: fit-content;
  margin: 10px auto; /* Center the block in the container */
  display: block; /* Change to block if it's not already */
  text-align: center; /* Center the text inside the block */
  
}

.audio-player {
  display: block;
  margin: 0 auto;
}

.filename-centre{
  text-align: center;
  padding-top: 10px;
}


.attribute-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: stretch; 
}

.instrument-title {
  font-size: 48px; 
  font-weight: bold;
  color: white; 
  letter-spacing: 2px; 
  margin: 0; 
  padding-bottom: 10px;
  border-radius: 5px; 
  text-align: center;
}

.instrument-attribute {
  width: 200px;
  min-height: 200px;
  margin-bottom: 20px;
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  background-color: #f3f3f3;
}

.attribute-name {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}

.attribute-value {
  font-size: 16px;
  line-height: 1.5;
}

.personality-overview {
  background-color: #ffe0df;
  color: #A13D2D;
}

.secret {
  background-color: #cfeccc;
  color: darkgreen;
}
.worst-trait {
  background-color: #aeaeec; 
  color: #420097;
}

.important {
  background-color: #add8e6; 
  color: rgb(0, 77, 128);
}

.dislikes-most {
  background-color: lightyellow; 
  color: #7e650b;
}

.plays-with-others {
  background-color: #fff1e2;
  color: rgb(211, 112, 0); 
}

.constituent-parts {
  background-color: #b19f8c; 
  color: #4b331a;
  }

.attribute-line {
  border: 0;
  border-top: 3px solid #666;
  margin: 10px 0;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding-bottom: 10px;
}